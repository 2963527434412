<template>
  <form @submit.prevent="updateContract">
    <modal-card title="Edit contract">
      <loading v-if="isLoading" />
      <div v-else>
        <b-field label="Status">
          <b-select v-model="form.status" expanded>
            <option
              v-for="{ value, label } in $_.orderBy(
                statuses,
                ['value'],
                ['asc']
              )"
              :key="value"
              :value="value"
              >{{ label }}</option
            >
          </b-select>
        </b-field>
        <b-field label="Currency">
          <b-select v-model="form.price.currency" expanded>
            <option v-for="c in currencies" :key="c.key" :value="c.key">
              {{ c.label }} ({{ c.prefix || c.suffix }})
            </option>
          </b-select>
        </b-field>
        <b-field label="Term price">
          <b-field>
            <b-input
              v-model.number="form.price.total"
              type="number"
              step="0.01"
              min="0"
              class="is-stretched"
            />
            <p class="control">
              <span class="button is-static">{{
                form.price.currency.toUpperCase()
              }}</span>
            </p>
          </b-field>
        </b-field>

        <b-switch v-model="form.price.isDiscountable"
          >Allow additional discounts</b-switch
        >

        <b-field label="Date next due" class="has-margin-top-100">
          <date-time-picker
            key="dateNextDue"
            :value="$moment(form.dateNextDue).format('YYYY-MM-DD HH:mm')"
            readonly
            :min-date="$moment().format('YYYY-MM-DD HH:mm')"
            format="YYYY-MM-DD HH:mm"
            :show-button="true"
            @input="newDateNextDue"
          />
        </b-field>

        <b-field
          label="Date credit quota resets (if applicable)"
          class="has-margin-top-100"
        >
          <date-time-picker
            key="dateQuotaResets"
            :value="$moment(form.dateQuotaResets).format('YYYY-MM-DD HH:mm')"
            readonly
            :min-date="$moment().format('YYYY-MM-DD HH:mm')"
            format="YYYY-MM-DD HH:mm"
            :show-button="true"
            @input="newDateQuotaResets"
          />
        </b-field>
      </div>
      <button
        slot="footer"
        :class="['is-success', isProcessing ? 'is-loading' : '']"
        :disabled="isDisabled"
        type="submit"
        class="button"
      >
        {{ "Save" }}
      </button>
    </modal-card>
  </form>
</template>

<script>
const currencies = require("@/data/currencies.json");
import { statuses } from "@/data/contract";
export default {
  name: "OverrideContractModal",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      form: {
        price: {},
        dateNextDue: new Date(),
        dateQuotaResets: new Date(),
        status: null
      },
      statuses
    };
  },
  computed: {
    contract() {
      return this.$store.getters["billing/contract"](this.siteId) || {};
    },
    contractDateNextDue() {
      return this.contract.dateNextDue.toDate();
    },
    contractDateQuotaResets() {
      return this.contract.dateQuotaResets.toDate();
    },
    contractPrice() {
      return this.$_.pick(this.contract.price, this.$_.keys(this.form.price));
    },
    currency() {
      return this.$_.get(this.contract, "price.currency", "gbp");
    },
    currencies() {
      return this.$_(currencies)
        .map((c, key) => {
          c["key"] = key;
          return c;
        })
        .orderBy(["code"], ["asc"])
        .value();
    },
    isValid() {
      if (
        this.$_.isEqual(this.form.price, this.contractPrice) &&
        this.$_.isEqual(this.form.dateNextDue, this.contractDateNextDue) &&
        this.$_.isEqual(
          this.form.dateQuotaResets,
          this.contractDateQuotaResets
        ) &&
        this.$_.isEqual(this.form.status, this.contract.status)
      )
        return false;
      if (this.form.price.total < 0) return false;
      return true;
    },
    isDisabled() {
      return !this.isValid || this.isProcessing;
    }
  },
  async created() {
    await this.$store.dispatch("billing/observeContract", {
      siteId: this.siteId
    });
    this.form.status = this.contract.status;
    this.form.dateNextDue = this.$moment(this.contractDateNextDue)
      .clone()
      .toDate();

    this.form.dateQuotaResets = this.$moment(this.contractDateQuotaResets)
      .clone()
      .toDate();
    this.form.price = this.$_.pick(this.contract.price, [
      "total",
      "isDiscountable",
      "currency"
    ]);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveContract", {
      siteId: this.siteId
    });
  },
  methods: {
    newDateNextDue($event) {
      this.form.dateNextDue = this.$moment($event, "YYYY-MM-DD HH:mm").toDate();
    },
    newDateQuotaResets($event) {
      this.form.dateQuotaResets = this.$moment(
        $event,
        "YYYY-MM-DD HH:mm"
      ).toDate();
    },
    updateContract() {
      if (this.isDisabled) return;
      this.isProcessing = true;
      this.$store
        .dispatch("billing/updateContract", {
          contractId: this.siteId,
          dateNextDue: this.form.dateNextDue,
          dateQuotaResets: this.form.dateQuotaResets,
          price: this.form.price,
          status: this.form.status
        })
        .then(() => {
          this.isProcessing = false;
          this.$toast.open({
            message: "Contract updated"
          });
          this.$emit("close");
        })
        .catch(message => {
          this.isProcessing = false;
          this.$toast.open({
            message: message || "Error updating contract",
            type: "is-danger"
          });
        });
    }
  }
};
</script>
